
import { Vue, Component, Prop } from "vue-property-decorator";
import Multiselect from "vue-multiselect";

import { SubscriptionService } from "@/api/SubscriptionServiceApi";
import { QuotationService } from "@/api/QuotationServiceApi";
import { RegionService } from "@/api/RegionServiceApi";

import SubscriptionPriceModel, {
  SubscriptionPriceModelDto
} from "@/models/SubscriptionPriceModel";

import {
  ISubscription,
  ISubscriptionCategory,
  ISubscriptionRegion
} from "@/models/Subscription";

import InvoicingMethod, { InvoicingMethodDto } from "@/models/InvoicingMethod";

import Region from "@/models/Region";

import SelectableOptions from "@/components/SelectableOptions.vue";
import QuotationCategory from "@/models/QuotationCategory";
import { Option } from "@/models/Option";
import { TranslateResult } from "vue-i18n";
import { GetLocale } from "@/utility/LanguageHelper";

@Component({
  components: {
    Multiselect,
    SelectableOptions
  }
})
export default class Subscription extends Vue {
  subscription: ISubscription = {
    invoicingMethodId: 0,
    priceModelId: 0,
    subscribedRegions: [],
    subscribedCategories: [],
    discountCodeId: 0
  };

  selectedPriceModel: SubscriptionPriceModel = new SubscriptionPriceModel(
    new SubscriptionPriceModelDto()
  );

  selectedInvoicingMethod: InvoicingMethod = new InvoicingMethod(
    new InvoicingMethodDto()
  );

  @Prop({
    default: () => ({
      id: 0,
      basePrice: 0,
      nrOfMonths: 0,
      monthlyPricePerExtraCategory: 0,
      monthlyPricePerExtraRegion: 0,
      monthlyPricePerMultipleExtraCategories: 0,
      monthlyPricePerMultipleExtraRegions: 0
    })
  })
  selectedPriceModelProxy!: SubscriptionPriceModel;

  @Prop({
    default: () => ({
      id: 0,
      method: ""
    })
  })
  selectedInvoicingMethodProxy!: InvoicingMethod;

  @Prop({
    default: () => ({
      id: 0,
      name: ""
    })
  })
  selectedRegionsProxy!: Array<Region>;

  @Prop({
    default: () => ({
      id: 0,
      name: ""
    })
  })
  selectedCategoriesProxy!: Array<QuotationCategory>;

  subscriptionPriceModels = Array<SubscriptionPriceModel>();
  invoicingMethods = Array<InvoicingMethod>();

  selectedCategories = Array<QuotationCategory>();
  selectedRegions = Array<Region>();

  categoryOptions = Array<QuotationCategory>();
  categoryOptionsReadOnly = Array<QuotationCategory>();

  regionOptions = Array<Region>();
  regionOptionsReadOnly = Array<Region>();

  async created() {
    await SubscriptionService.GetSubscriptionPriceModels()
      .then(response => {
        this.subscriptionPriceModels = response;
        if (this.selectedPriceModelProxy.id > 0) {
          this.selectedPriceModel = this.selectedPriceModelProxy;
        } else {
          this.selectedPriceModel = this.subscriptionPriceModels[0];
        }
      })
      .catch(err => {
        return new Error(err.message);
      });

    await SubscriptionService.GetInvoicingMethods()
      .then(response => {
        this.invoicingMethods = response;
        if (this.selectedInvoicingMethodProxy.id > 0) {
          this.selectedInvoicingMethod = this.selectedInvoicingMethodProxy;
        } else {
          this.selectedInvoicingMethod = this.invoicingMethods[0];
        }
      })
      .catch(err => {
        return new Error(err.message);
      });

    await QuotationService.GetCategories()
      .then(response => {
        this.categoryOptionsReadOnly = response;
        this.categoryOptions = [...response];
      })
      .catch(err => {
        return new Error(err.message);
      });

    await RegionService.GetRegions()
      .then(response => {
        this.regionOptionsReadOnly = response;
        this.regionOptions = [...response];
      })
      .catch(err => {
        return new Error(err.message);
      });

    if (this.selectedCategoriesProxy.length > 0) {
      this.selectedCategories = [...this.selectedCategoriesProxy];
    }

    if (this.selectedRegionsProxy.length > 0) {
      this.selectedRegions = [...this.selectedRegionsProxy];
    }

    this.$emit("loading", false);
  }

  monthsString(numberOfMonths: number): TranslateResult {
    if (numberOfMonths > 1) {
      return this.$t("GENERAL.MONTHS")
        .toString()
        .toLowerCase();
    }
    return this.$t("GENERAL.MONTH")
      .toString()
      .toLowerCase();
  }

  get calculateSubscriptionPrice(): number {
    let monthlySubscription = 0;
    if (this.selectedPriceModel != null && this.selectedPriceModel.id > 0) {
      let baseSubscription = this.selectedPriceModel.basePrice;
      monthlySubscription += baseSubscription;

      let selectedCategoriesCount = this.selectedCategories.length;
      monthlySubscription += this.calculateCostPerOptionsCount(
        selectedCategoriesCount,
        this.selectedPriceModel.monthlyPricePerExtraCategory,
        this.selectedPriceModel.monthlyPricePerMultipleExtraCategories
      );

      let selectedRegionsCount = this.selectedRegions.length;
      monthlySubscription += this.calculateCostPerOptionsCount(
        selectedRegionsCount,
        this.selectedPriceModel.monthlyPricePerExtraRegion,
        this.selectedPriceModel.monthlyPricePerMultipleExtraRegions
      );
    }

    return monthlySubscription;
  }

  calculateCostPerOptionsCount(
    optionsCount: number,
    pricePerExtraOption: number,
    pricePerMultipleExtraOptions: number
  ): number {
    if (optionsCount >= 3) {
      return pricePerMultipleExtraOptions * this.selectedPriceModel.nrOfMonths;
    }

    if (optionsCount >= 2) {
      return pricePerExtraOption * this.selectedPriceModel.nrOfMonths;
    }

    return 0;
  }

  mapOptionsToCategories(options: Array<Option>): void {
    this.selectedCategories = [];
    options.forEach(option => {
      for (let i = 0; i < this.categoryOptionsReadOnly.length; i++) {
        if (this.categoryOptionsReadOnly[i].id == option.id) {
          this.selectedCategories.push(this.categoryOptionsReadOnly[i]);
          break;
        }
      }
    });
    this.emitSubscription();
  }

  mapCategoriesToOptions(categories: Array<QuotationCategory>): Array<Option> {
    let options: Array<Option> = [];
    if (categories.length > 0) {
      categories.forEach(categoryOption => {
        options.push({
          id: categoryOption.id,
          name: this.$t(
            `QUOTATION_REQUEST_CATEGORY.${categoryOption.name}`
          ).toString()
        });
      });
    }

    return options;
  }
  mapRegionsToOptions(regions: Array<Region>): Array<Option> {
    const translate: boolean = GetLocale() == "fi";
    let options: Array<Option> = [];
    if (regions.length > 0) {
      regions.forEach(regionOption => {
        let regionName: string;
        if (translate) {
          regionName = this.$t(`REGION.${regionOption.name}`).toString();
        } else {
          regionName = regionOption.name;
        }
        let option: Option = {
          id: regionOption.id,
          name: regionName
        };
        options.push(option);
      });
    }

    return options;
  }

  mapOptionsToRegions(options: Array<Option>): void {
    this.selectedRegions = [];
    options.forEach(option => {
      for (let i = 0; i < this.regionOptionsReadOnly.length; i++) {
        if (this.regionOptionsReadOnly[i].id == option.id) {
          this.selectedRegions.push(this.regionOptionsReadOnly[i]);
          break;
        }
      }
    });
    this.emitSubscription();
  }

  mapSelectedRegions(): Array<ISubscriptionRegion> {
    let slimRegions: Array<ISubscriptionRegion> = [];
    this.selectedRegions.forEach(region => {
      slimRegions.push({
        id: region.id
      });
    });

    return slimRegions;
  }

  mapSelectedCategories(): Array<ISubscriptionCategory> {
    let slimCategories: Array<ISubscriptionCategory> = [];
    this.selectedCategories.forEach(category => {
      slimCategories.push({
        id: category.id
      });
    });

    return slimCategories;
  }

  emitSubscription() {
    this.subscription = {
      invoicingMethodId: 0,
      priceModelId: 0,
      subscribedRegions: [],
      subscribedCategories: [],
      discountCodeId: 0
    };

    this.subscription.invoicingMethodId = this.selectedInvoicingMethod.id;
    this.subscription.priceModelId = this.selectedPriceModel.id;
    this.subscription.subscribedRegions = this.mapSelectedRegions();
    this.subscription.subscribedCategories = this.mapSelectedCategories();
    this.$emit("subscription", this.subscription);
  }

  get invoicedEveryMonthTranslation(): string {
    if (this.selectedPriceModel.nrOfMonths > 1) {
      return "";
    } else {
      return this.$t("COMPANY_REGISTER.INVOICED_EVERY").toString();
    }
  }
}
