
import { Vue, Component, Prop } from "vue-property-decorator";
import { ISubscriptionDetails } from "@/models/SubscriptionDetails";
import Subscription from "@/components/Dashboard/Admin/Components/Subscription.vue";
import { ISubscription } from "@/models/Subscription";

@Component({
  components: {
    Subscription
  }
})
export default class DashboardSubscription extends Vue {
  subscription: ISubscription = {
    invoicingMethodId: 0,
    priceModelId: 0,
    subscribedRegions: [],
    subscribedCategories: [],
    discountCodeId: 0
  };

  @Prop({})
  currentSubscription!: ISubscriptionDetails;

  created() {
    const priceModel = this.subscriptionDetails.subscriptionPriceModel;
    const invoicingMethod = this.subscriptionDetails.invoicingMethod;

    this.subscription.priceModelId = priceModel.id;
    this.subscription.invoicingMethodId = invoicingMethod.id;

    this.subscriptionDetails.subscribedRegions.forEach(region => {
      this.subscription.subscribedRegions.push({ id: region.id });
    });

    this.subscriptionDetails.subscribedCategories.forEach(category => {
      this.subscription.subscribedCategories.push({ id: category.id });
    });
  }

  get subscriptionDetails(): ISubscriptionDetails {
    return this.currentSubscription;
  }

  get subscriptionHasExpired(): boolean {
    return this.subscriptionDetails!.expired;
  }

  async buySubscription() {
    this.$emit("buySubscription", this.subscription);
  }

  setSubscription(subscription: ISubscription) {
    this.subscription = subscription;
  }
}
