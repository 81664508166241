
import Multiselect from "vue-multiselect";
import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import { ICompanySearchResult } from "@/models/CompanySearchResult";
import { AdminService } from "@/api/AdminServiceApi";
import { TranslateResult } from "vue-i18n";
import { ICompanyFullDetails } from "@/models/CompanyFullDetails";
import DashboardSubscription from "@/components/Dashboard/Admin/DashboardSubscription.vue";
import { ISubscription } from "@/models/Subscription";

@Component({
  components: {
    Multiselect,
    DashboardSubscription
  }
})
export default class DashboardViewCompany extends Vue {
  options: Array<ICompanySearchResult> = [];
  searchQuery: string = "";
  isEditingCompany: boolean = false;
  isEditingUser: boolean = false;
  selectedCompany: ICompanyFullDetails = {
    id: 0,
    created: "",
    companyName: "",
    businessCode: "",
    contactPerson: "",
    renewSubscriptionAutomatically: false,
    subscriptionDetails: {
      invoicingMethod: { id: 0, method: "" },
      expired: false,
      created: "",
      expiryDate: "",
      discountCode: "",
      subscriptionPriceModel: {
        id: 0,
        basePrice: 0,
        nrOfMonths: 0,
        monthlyPricePerExtraCategory: 0,
        monthlyPricePerMultipleExtraCategories: 0,
        monthlyPricePerExtraRegion: 0,
        monthlyPricePerMultipleExtraRegions: 0
      },
      subscribedRegions: [],
      subscribedCategories: []
    },
    user: {
      id: 0,
      email: "",
      mobilePhone: "",
      emailVerified: false,
      acceptedTermsAndConditions: false,
      language: "",
      authLogs: [],
      emails: []
    }
  };

  async search(searchQuery: string) {
    if (searchQuery.length > 0) {
      await AdminService.SearchFromCompany(searchQuery).then(response => {
        this.options = response.data;
      });
    }
  }

  async viewCompany(option: ICompanySearchResult) {
    this.refreshCompany();
    await AdminService.GetCompanyDetails(option.id).then(response => {
      this.selectedCompany = response.data;
    });
  }

  customLabel(option: ICompanySearchResult): TranslateResult {
    return `${option.companyName} - (${option.businessCode})`;
  }

  refreshCompany() {
    this.selectedCompany = {
      id: 0,
      created: "",
      companyName: "",
      businessCode: "",
      contactPerson: "",
      renewSubscriptionAutomatically: false,
      subscriptionDetails: {
        invoicingMethod: { id: 0, method: "" },
        expired: false,
        created: "",
        expiryDate: "",
        discountCode: "",
        subscriptionPriceModel: {
          id: 0,
          basePrice: 0,
          nrOfMonths: 0,
          monthlyPricePerExtraCategory: 0,
          monthlyPricePerMultipleExtraCategories: 0,
          monthlyPricePerExtraRegion: 0,
          monthlyPricePerMultipleExtraRegions: 0
        },
        subscribedRegions: [],
        subscribedCategories: []
      },
      user: {
        id: 0,
        email: "",
        mobilePhone: "",
        emailVerified: false,
        acceptedTermsAndConditions: false,
        language: "",
        authLogs: [],
        emails: []
      }
    };
  }

  enableCompanyEditing() {
    this.isEditingCompany = !this.isEditingCompany;
  }

  async updateCompanyDetails() {
    this.isEditingCompany = false;

    await AdminService.UpdateCompanyDetails(
      this.selectedCompany.id,
      this.selectedCompany.companyName,
      this.selectedCompany.businessCode,
      this.selectedCompany.contactPerson,
      this.selectedCompany.renewSubscriptionAutomatically
    ).catch(error => {
      console.log(error);
      this.$swal.fire({
        titleText: "Någonting gick fel",
        confirmButtonText: "Ok",
        icon: "error"
      });
    });

    AdminService.GetCompanyDetails(this.selectedCompany.id).then(response => {
      this.selectedCompany = response.data;
    });
  }

  async updateUserDetails() {
    this.isEditingUser = false;

    await AdminService.UpdateUserDetails(
      this.selectedCompany.user.id,
      this.selectedCompany.user.email,
      this.selectedCompany.user.mobilePhone,
      this.selectedCompany.user.language
    ).catch(error => {
      console.log(error);
      this.$swal.fire({
        titleText: "Någonting gick fel",
        confirmButtonText: "Ok",
        icon: "error"
      });
    });

    AdminService.GetCompanyDetails(this.selectedCompany.id).then(response => {
      this.selectedCompany = response.data;
    });
  }

  enableUserEditing() {
    this.isEditingUser = !this.isEditingUser;
  }

  async buySubscription(subscription: ISubscription) {
    this.$swal
      .fire({
        titleText: this.$t("MODAL.SUBSCRIPTION_CONFIRMATION.TITLE").toString(),
        text: this.$t("MODAL.SUBSCRIPTION_CONFIRMATION.SUB_TITLE").toString(),
        confirmButtonText: "Ja",
        icon: "question",
        showCloseButton: true
      })
      .then(async result => {
        if (result.isConfirmed) {
          await AdminService.BuySubscription(
            this.selectedCompany.id,
            subscription
          )
            .then(() => {
              this.$swal.fire({
                titleText: this.$t(
                  "MODAL.SUBSCRIPTION_CONFIRMATION.TITLE"
                ).toString(),
                text: this.$t(
                  "MODAL.SUBSCRIPTION_CONFIRMATION.SUCCESS"
                ).toString(),
                confirmButtonText: "Ok",
                icon: "success"
              });
            })
            .catch(() => {
              this.$swal.fire({
                titleText: this.$t(
                  "MODAL.SUBSCRIPTION_CONFIRMATION.TITLE"
                ).toString(),
                text: "Något gick fel",
                confirmButtonText: "Ok",
                icon: "error"
              });
            });
        }
      });
  }
}
